@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 300;
  font-size: 14px;
}

.listItem:hover {
  background: #e2e8f0;
  cursor: pointer;
}

@keyframes octocat-wave {
  0%, 100% {
    transform:rotate(0);
  }
  20%, 60% {
    transform:rotate(-20deg);

  } 40%, 80% {
    transform:rotate(10deg);
  }
}
