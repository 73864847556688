.App {
  text-align: center;
  margin: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 95vh;
}

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: white;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

.container {
  max-width: unset !important;
}
.front {
  z-index: 100;
}
.slanted {
  z-index: -1;
  transform: skewY(3deg);
  margin-top: -5%;
}
.slanted-contents {
  transform: skewY(-3deg);
  padding-top: 5em;
}

.slanted-x {
  transform: skewx(14deg);
}
.slanted-x-contents {
  transform: skewx(-14deg) skewY(3deg) !important;
}

.left {
  /*overflow: hidden;*/
  margin-left: -5% !important;
}

.bigly {
  width: 120%;
}

.pointer {
  cursor: pointer;
}
.zoom {
  padding: 50px;
  transition: transform .2s; /* Animation */
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

.zoom:hover {
  transform: scale(1.5); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.big-button-h {
  height: 100%;
}

.big-button-container {
  height: 20em !important;
}

.button-l {
  font-size: 1.5rem !important;
}

.slanted-r {
  z-index: -1;
  transform: skewY(-3deg);
  margin-top: -5%;
}
.slanted-contents-r {
  transform: skewY(3deg);
}

.cent {
  display: flex;
  width: 100%;
  max-width: 60%;
  margin: 0 auto;
  justify-content: center;
}

.slanted-banner {
  margin-top: -7em;
  height: 25em !important;
}

.navbar.navbar-light.transparent {
  background: transparent !important;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}

.accordion-item, .accordion-button {
  border-radius: 0 !important;
  box-shadow: none !important;
}

.form-check-input {
  clear: left;
}

.form-switch.form-switch-sm {
  margin-bottom: 0.5rem; /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-sm .form-check-input {
  height: 1rem;
  width: calc(1rem + 0.75rem);
  border-radius: 2rem;
}

.form-switch.form-switch-md {
  margin-bottom: 1rem; /* JUST FOR STYLING PURPOSE */
  outline: black 2rem;
}

.form-switch.form-switch-sm {
  margin-bottom: 1rem; /* JUST FOR STYLING PURPOSE */
  outline: black 2rem;
}

.form-switch.form-switch-sm.nm {
  margin-bottom: 0 !important; /* JUST FOR STYLING PURPOSE */
  max-width: 40px !important;
}

.form-switch.form-switch-md .form-check-input {
  height: 1.5rem;
  width: calc(2rem + 0.75rem);
  border-radius: 3rem;
  outline: black 2rem;
  box-shadow: 0 0 0 0.15rem black;
}

.form-switch.form-switch-lg {
  margin-bottom: 1.5rem; /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-lg .form-check-input {
  height: 2rem;
  width: calc(3rem + 0.75rem);
  border-radius: 4rem;
}

.form-switch.form-switch-xl {
  margin-bottom: 2rem; /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-xl .form-check-input {
  height: 2.5rem;
  width: calc(4rem + 0.75rem);
  border-radius: 5rem;
}
.dropdown-toggle::after {
  display: none !important;
}
.list-group{
  max-height: 500px;
  margin-bottom: 10px;
  overflow:scroll;
  -webkit-overflow-scrolling: touch;
}

.inbox {
  height: 80vh;
}

.shadowed-card {
  box-shadow: inset 0px 0px 5px 3px rgb(20, 20, 20);

}

.green-background {
  background: #4ade80 !important;
}

.small-row {
  max-height: 4em;
}

.modal-height {
  height: 90% !important;
}
.timeline {
  border-left: 1px solid hsl(0, 0%, 90%);
  position: relative;
  list-style: none;
}

.timeline .timeline-item {
  position: relative;
}

.timeline .timeline-item:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline .timeline-item:after {
  background-color: hsl(0, 0%, 90%);
  left: -38px;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  content: "";
}

.timeline-with-icons {
  border-left: 1px solid hsl(0, 0%, 90%);
  position: relative;
  list-style: none;
}

.timeline-with-icons .timeline-item {
  position: relative;
}

.timeline-with-icons .timeline-item:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline-with-icons .timeline-icon {
  position: absolute;
  left: -48px;
  background-color: hsl(217, 88.2%, 90%);
  color: hsl(217, 88.8%, 35.1%);
  border-radius: 50%;
  height: 31px;
  width: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline-with-icons .timeline-icon-completed {
  position: absolute;
  left: -48px;
  background-color: forestgreen;
  color: hsl(217, 88.8%, 35.1%);
  border-radius: 50%;
  height: 31px;
  width: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.tile-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  background-color: darkslateblue;
}

.tile:hover .tile-overlay {
  opacity: 100%;
}